import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";
import http from "../../services/httpService";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import MovieSpinner from "./../../components/movieSpinner";
import { selectLoadingStatus } from "../../redux/movies/movies.selector";
import { selectAllGenres } from "./../../redux/moviesGenre/genres.selector";
import {
  selectAllCountries,
  selectAllLanguages,
} from "./../../redux/country/country.selector";
import { uploadMoviePicture } from "../../services/movieServices";
import { addMovieAsync } from "../../redux/movies/movies.action";
import { fetchGenresAsync } from "../../redux/moviesGenre/genres.action";
import {
  fetchCountryAsync,
  fetchLanguageAsync,
} from "../../redux/country/country.action";
import { toast } from "react-toastify";

import "../../stylesheets/main.css";

const movieURL = "/movies/upload-movie-video";
const videoIdURL = "/videoId";
const movieVideoURL = "/movies/upload-video";

class AddMovieWithFile extends Form {
  state = {
    data: {
      title: "",
      price: "",
      rating: "",
      description: "",
      actor: [],
      name: "",
      countryName: "",
      language: "",
      duration: "",
      isBanner: "",
      isFree: "",
      viewersLimit: "",
      releaseType: "",
      releaseYear: "",
      moviePictureURL: "",
      movieVideoURL: "",
      movieTrailerURL: "",
      movieBannerPictureURL: "",
    },
    picture: false,
    bannerPicture: false,
    trailerVideo: false,
    movieVideo: false,
    actors: [],
    allGenres: [],
    allCountries: [],
    languages: [],
    isBanner: ["No, don't set as banner", "Yes, set as banner"],
    isFree: ["No", "Yes"],
    type: ["New Releases", "Trending", "Old Movie"],
    isLoading: false,
    trailerCompleted: 0,
    videoCompleted: 0,
    error: {},
  };

  schema = {
    title: Joi.string().min(3).max(255).required().label("Title"),
    price: Joi.number().min(0.99).max(100).required().label("Price"),
    rating: Joi.number().min(6).max(18).label("Rating"),
    description: Joi.string()
      .min(3)
      .max(100000)
      .required()
      .label("Description"),
    actor: Joi.array().items(Joi.string()),
    name: Joi.string().required().label("Genre"),
    duration: Joi.number().min(1).required().label("Duration in minutes"),
    isBanner: Joi.string().label("Is Banner"),
    isFree: Joi.string().label("Is Free"),
    viewersLimit: Joi.number().min(1).max(2000).label("Viewers Limit"),
    releaseType: Joi.string().max(255).label("Release Type"),
    releaseYear: Joi.string().max(30).label("Release Year"),
    countryName: Joi.string().min(3).max(255).required(),
    language: Joi.string().min(3).max(255).required(),
  };

  doSubmit = async () => {
    const { addMovieAsync } = this.props;

    const {
      title,
      price,
      rating,
      description,
      actor,
      name,
      countryName,
      language,
      duration,
      isBanner,
      isFree,
      viewersLimit,
      releaseType,
      releaseYear,
    } = this.state.data;

    if (this.state.movieVideo.size > 8388608000) {
      toast.info("The movie file size should not be more than 8 GB");
      return;
    }

    if (!this.state.picture) {
      toast.info("Please set a movie picture");
      return;
    }
    if (!this.state.trailerVideo) {
      toast.info("Please select a video for trailer");
      return;
    }
    if (!this.state.movieVideo) {
      toast.info("Please select movie video");
      return;
    }
    if (isBanner === "Yes, set as banner" && !this.state.bannerPicture) {
      toast.info("Please select a banner picture.");
      return;
    }
    if (this.state.actors.length <= 0) {
      toast.info("Enter a least one actor name to proceed.");
      return;
    }

    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("file", this.state.picture, `${this.state.data.title}.png`);

    const bannerFormData = new FormData();
    if (this.state.bannerPicture) {
      bannerFormData.append(
        "file",
        this.state.bannerPicture,
        `${this.state.data.title}-banner.png`
      );
    }

    const formData2 = new FormData();
    formData2.append(
      "video",
      this.state.trailerVideo,
      `${this.state.trailerVideo.name}`
    );

    const formData3 = new FormData();
    formData3.append(
      "video",
      this.state.movieVideo,
      `${this.state.movieVideo.name}`
    );

    try {
      const { data: trailerData } = await http.post(movieVideoURL, formData2, {
        onUploadProgress: (progressEvent) => {
          //console.log(progressEvent.loaded / progressEvent.total);
          this.setState({
            trailerCompleted: (
              (progressEvent.loaded * 100) /
              progressEvent.total
            ).toFixed(),
          });
        },
      });

      //const { data: videoData } =
      await http.post(movieURL, formData3, {
        onUploadProgress: (progressEvent) => {
          // console.log(
          //   (progressEvent.loaded * 100) / (progressEvent.total * 100)
          // );
          this.setState({
            videoCompleted: (
              (progressEvent.loaded * 100) /
              progressEvent.total
            ).toFixed(),
          });
        },
      });

      const { data: pictureData } = await uploadMoviePicture(formData);

      let bannerPictureData;
      if (this.state.bannerPicture) {
        const { data } = await uploadMoviePicture(bannerFormData);
        bannerPictureData = data;
      }

      const flooredDuration = Math.floor(duration);

      setTimeout(async () => {
        const { data: Id } = await http.get(videoIdURL);

        addMovieAsync({
          title,
          price,
          rating,
          description,
          actor,
          genre: name,
          countryName,
          language,
          duration: flooredDuration,
          isBanner: isBanner === "Yes, set as banner" ? true : false,
          isFree: isFree === "Yes" ? true : false,
          viewersLimit,
          releaseType,
          releaseYear,
          videoId: Id.videoId,
          movieVideoURL: `https://watch.videodelivery.net/${Id.videoId}`,
          movieTrailerURL: trailerData.Location,
          moviePictureURL: pictureData.fileLocation,
          movieBannerPictureURL: bannerPictureData?.fileLocation,
        });
      }, 5000);

      this.setState({
        data: {
          title: "",
          price: "",
          rating: "",
          description: "",
          actor: "",
          name: "",
          duration: "",
          isBanner: "",
          isFree: "",
          viewersLimit: "",
          countryName: "",
          language: "",
          releaseType: "",
          releaseYear: "",
          moviePictureURL: "",
          movieVideoURL: "",
          movieTrailerURL: "",
          movieBannerPictureURL: "",
        },
        picture: false,
        bannerPicture: false,
        movieVideo: false,
        trailerVideo: false,
        trailerCompleted: 0,
        videoCompleted: 0,
      });
      this.setState({ isLoading: false });
      toast.info("Saving movie, please wait...");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
        setTimeout(() => (window.location = "/add-movie-file/new"), 3000);
      }
    }
  };

  handleBannerPictureSelect = (e) => {
    var bannerPicture = e.target.files[0];
    this.setState({ bannerPicture });
  };

  handlePictureSelect = (e) => {
    var picture = e.target.files[0];
    this.setState({ picture });
  };

  handleTrailerSelect = (e) => {
    var trailerVideo = e.target.files[0];
    this.setState({ trailerVideo });
  };

  handleVideoSelect = (e) => {
    var movieVideo = e.target.files[0];
    this.setState({ movieVideo });
  };

  handleKeyDown = (e) => {
    if (e.keyCode !== 39) return;
    const value = e.target.value;
    if (!value.trim()) return;
    this.setState({ actors: [...this.state.actors, value] });
    this.setState({
      data: {
        ...this.state.data,
        actor: [...this.state.actors, value],
      },
    });
    e.target.value = "";
  };

  removeActor = (index) => {
    const newActors = this.state.actors.filter((actor, i) => i !== index);
    this.setState({ actors: newActors });
    this.setState({
      data: {
        ...this.state.data,
        actor: newActors,
      },
    });
  };

  componentDidMount() {
    document.title = "African Movies | Add Movie File";
    const {
      allGenres,
      languages,
      allCountries,
      fetchGenresAsync,
      fetchCountryAsync,
      fetchLanguageAsync,
    } = this.props;
    fetchGenresAsync();
    fetchCountryAsync();
    fetchLanguageAsync();
    this.setState({
      allGenres,
      allCountries,
      languages,
      data: {},
    });
  }

  render() {
    const { isLoading, trailerCompleted, videoCompleted } = this.state;
    return isLoading ? (
      <React.Fragment>
        <h2>
          Trailer Video Upload Progress :{" "}
          <span style={{ color: "#3b7ddd", fontWeight: "bold" }}>
            {" "}
            {trailerCompleted} %
          </span>
        </h2>{" "}
        <br />
        <h2>
          Movie Video Upload Progress :{" "}
          <span style={{ color: "#3b7ddd", fontWeight: "bold" }}>
            {" "}
            {videoCompleted} %
          </span>
        </h2>{" "}
        <br />
        <MovieSpinner
          trailerCompleted={trailerCompleted}
          videoCompleted={videoCompleted}
        />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div
              className="col-md-12 bg-light-black"
              style={{ borderRadius: "8px" }}
            >
              <div
                className="container  d-flex h-100"
                style={{
                  maxHeight: "auto",
                  marginBottom: "20px",
                }}
              >
                <span className="m-auto">
                  <form action="dashboard.html" onSubmit={this.handleSubmit}>
                    <span>Note: Field mark with * are complusory</span>
                    <br />
                    {this.renderInput("text", "title", "Title", "Title *")}
                    {/* {this.renderInput("text", "actor", "Actor", "Actor *")} */}
                    <div>
                      <span>Actors*</span>
                      <div className="tags-input-container">
                        {this.state.actors.map((actor, index) => (
                          <div className="tag-item" key={index}>
                            <span className="text">{actor}</span>
                            <span
                              className="close"
                              onClick={() => this.removeActor(index)}
                            >
                              &times;
                            </span>
                          </div>
                        ))}
                        <input
                          type="text"
                          onKeyDown={this.handleKeyDown}
                          placeholder="Enter actors name"
                          className="tags-input"
                        />
                      </div>
                    </div>
                    {this.renderTextArea(
                      "text",
                      "description",
                      "Description",
                      "Description *"
                    )}
                    {this.renderInput("number", "price", "Price", "Price *")}
                    {this.renderSelect(
                      "name",
                      ["Click to Select", ...this.state.allGenres],
                      "Genre *"
                    )}
                    <br />
                    {this.renderSelect(
                      "countryName",
                      ["Click to Select", ...this.state.allCountries],
                      "Country *"
                    )}
                    <br />
                    {this.renderSelect(
                      "language",
                      [
                        "Click to Select",
                        ...this.state.languages.filter(
                          (l) => l.countryName === this.state.data.countryName
                        ),
                      ],
                      "Language *"
                    )}
                    <br />
                    {this.renderInput(
                      "number",
                      "duration",
                      "Duration in minutes",
                      "Duration in minutes *"
                    )}
                    {this.renderSelect(
                      "isBanner",
                      [...this.state.isBanner],
                      "Set as Banner"
                    )}
                    <br />
                    {this.renderSelect(
                      "isFree",
                      [...this.state.isFree],
                      "Is Movie Free"
                    )}
                    {this.state.data.isFree === "Yes" ? <br /> : null}
                    {this.state.data.isFree === "Yes"
                      ? this.renderInput(
                          "number",
                          "viewersLimit",
                          "Viewers Limit",
                          "Viewers Limit (Default: 1000 viewers)"
                        )
                      : null}
                    <br />
                    {this.renderSelect(
                      "releaseType",
                      ["Click to Select", ...this.state.type],
                      "Release Type"
                    )}
                    <br />
                    {this.renderInput(
                      "text",
                      "releaseYear",
                      "Release Year",
                      "Release Year"
                    )}
                    {this.renderInput("number", "rating", "Rating", "Rating")}
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Movie Banner picture
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="movieBannerPictueURL"
                          onChange={this.handleBannerPictureSelect}
                          className="form-control"
                          placeholder="movie picture"
                          aria-label="movie picture"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Movie Thumbnail picture *
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="moviePictueURL"
                          onChange={this.handlePictureSelect}
                          className="form-control"
                          placeholder="movie picture"
                          aria-label="movie picture"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Trailer Video *
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="movieTrailerURL"
                          onChange={this.handleTrailerSelect}
                          className="form-control"
                          placeholder="movie trailer"
                          aria-label="movie trailer"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Movie Video *
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="movieVideoURL"
                          onChange={this.handleVideoSelect}
                          className="form-control"
                          placeholder="movie video"
                          aria-label="movie video"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    <br />
                    {this.renderButton("Add Movie")}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
  allCountries: selectAllCountries,
  allGenres: selectAllGenres,
  languages: selectAllLanguages,
});

const mapDispatchToProps = (dispatch) => ({
  addMovieAsync: (payload) => dispatch(addMovieAsync(payload)),
  fetchGenresAsync: () => dispatch(fetchGenresAsync()),
  fetchCountryAsync: () => dispatch(fetchCountryAsync()),
  fetchLanguageAsync: () => dispatch(fetchLanguageAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMovieWithFile);
